var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-main',{staticClass:"ma-0"},[_c('BannerCarousel'),_c('v-container',[_c('Title',{attrs:{"title":"Destaques"}}),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.products),function(product,idx){return _c('v-col',{key:idx,attrs:{"cols":"6","md":"3","xl":"2"}},[_c('ProductCard',{attrs:{"product":product,"minHeight":_vm.$vuetify.breakpoint.mobile ? 200 : 370}})],1)}),1),_c('Title',{attrs:{"title":"Na magazine Brindes você encontra"}}),_c('v-card',{staticClass:"elevation-4"},[_c('v-row',_vm._l((_vm.categories),function(category,idx){return _c('v-col',{key:idx,attrs:{"align":"center","cols":"6","md":"2","lg":"2"}},[_c('v-avatar',{attrs:{"size":_vm.$vuetify.breakpoint.xl ? 180 : 120,"color":"secondary"}},[_c('v-img',{attrs:{"contain":"","src":category.src}})],1),_c('h6',{staticClass:"primary--text text-subtitle-2 text-lg-h6 font-weight-bold"},[_vm._v(" "+_vm._s(category.title)+" ")])],1)}),1)],1),_c('v-row',{staticClass:"my-16"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-0",attrs:{"flat":"","rounded":"lg"}},[_c('v-img',{attrs:{"cover":"","src":_vm.banner_card != null
                ? _vm.$store.state.imagePath + _vm.banner_card.image_path
                : '@/assets/banner02.png'}})],1)],1)],1),_c('Title',{attrs:{"title":"Novidades da semana"}}),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.products_new),function(product,idx){return _c('v-col',{key:idx,attrs:{"cols":"6","md":"3","xl":"2"}},[_c('ProductCard',{attrs:{"product":product,"minHeight":_vm.$vuetify.breakpoint.mobile ? 200 : 370}})],1)}),1),_c('v-row',{staticClass:"my-16"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-0",attrs:{"flat":"","rounded":"lg"}},[_c('v-img',{attrs:{"cover":"","src":_vm.banner_bottom != null
                ? _vm.$store.state.imagePath + _vm.banner_bottom.image_path
                : '@/assets/banner03.png'}})],1)],1)],1),_c('Title',{attrs:{"title":"Clientes Satisfeitos"}}),_c('v-slide-group',{staticClass:"pa-4 mb-15",attrs:{"active-class":"success","show-arrows":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.clients),function(item,idx){return _c('v-slide-item',{key:idx,staticClass:"mx-2"},[_c('v-avatar',{attrs:{"size":"120"}},[_c('v-img',{attrs:{"src":item != null
                ? _vm.$store.state.imagePath + item.image_path
                : '@/assets/banner03.png'}})],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }